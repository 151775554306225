@import "styles/colors";

.create-contract-form-wrapper {
  p {
    margin: 0;
  }

  .title {
    color: $primary-button-green;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .description {
    font-size: 14px;
    color: $note-message-green;
    margin-top: 36px;
  }

  .template-button {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: $primary-button-green;
    display: flex;
    align-items: center;
    margin: 9px 0 9px -15px;

    img {
      margin-left: 10px;
    }
  }

  .dropzone {
    background-color: $background-gray;
    padding: 26px 40px;
    border-radius: 4px;
    outline: 1px dashed $border-gray;
    text-align: center;
    margin-right: 40px;

    p {
      color: $note-message-green;
      font-size: 14px;
      margin: 10px 0 0 0;
    }
  }

  .file {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 0;
    margin-top: 10px;

    &-icon {
      margin-top: 4px;
    }

    &-info {
      flex: 1;
      font-size: 14px;

      &-title {
        color: $note-message-green;
        font-weight: 500;
      }

      &-size {
        color: $note-message-green;

        span {
          margin-left: auto;
        }
      }

      &-status {
        color: $primary-button-green;
        font-weight: 500;

        &-error {
          color: $warning-red-text;
          font-weight: 500;
        }
      }
    }

    &-remove {
      margin: auto 0;
      cursor: pointer;
    }
  }

  .divider-wrapper {
    display: flex;
    align-items: center;
    margin: 20px 0;

    div {
      background-color: $secondary-light-grey;
      height: 1px;
      width: calc(50% - 17px);
    }

    p {
      color: $secondary-light-grey;
      text-align: center;
      width: 34px;
    }
  }

  .footer-info {
    color: $nav-link-hover-green;
    margin-bottom: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .create-button {
    font-weight: 500;
  }

  .title-info {
    color: $note-message-green;
    text-align: center;
    margin-bottom: 15px;
  }

  .ant-progress {
    padding: 0 30px;
    margin: 0;

    .ant-progress-bg {
      background-color: $primary-button-green;
      height: 3px !important;
    }
  }

  .success-title {
    font-size: 20px;
    font-weight: 700;
    color: $primary-button-green;
    text-align: center;
    margin-top: 10px;
  }

  .success-info {
    color: $note-message-green;
    margin-top: 15px;
    padding: 0 30px;

    b {
      font-weight: 600;
      color: $primary-button-green;
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }
}

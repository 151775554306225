@import "styles/colors.scss";

.contract-new-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contract-new-header {
  height: 52px;
  display: flex;
  align-items: center;
  background: $background-gray;
  padding: 0 0 0 50px;
  min-width: 100%;
}

.contract-new-header-title {
  color: $primary-button-green;
  margin: 0 auto;
  line-height: 32px;
  cursor: default;
}

.contract-new-title {
  color: $primary-button-green;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 15px;
}

.contract-new-subtitle {
  color: $primary-background-green;
  text-align: center;
}

.contract-new-wrapper {
  display: flex;
  margin: 0 auto;
}

.contract-new-header-button {
  margin: 30px 35px 40px auto;
}

.contract-new-form {
  margin: 50px 90px 68px;
  min-width: 500px;

  &-title {
    font-size: 24px;
    font-weight: 500;
    color: $primary-button-green;
    margin-bottom: 30px;
  }
}

.contract-new-form-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .back-button {
    margin-bottom: 25px;

    img {
      margin-right: 10px;
      margin-bottom: -1px;
    }

    span {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .forward-button {
    width: 142px;

    & > img {
      margin-left: 10px;
      margin-bottom: -2px;
    }
  }
}
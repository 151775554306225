@import "styles/colors.scss";

.filter-wrapper {
  background-color: $background-gray;
  padding: 15px 15px 0 15px;
  border-radius: 10px;
  margin-bottom: 28px;

  .ant-form-item-label label {
    font-weight: 500;
  }

  &.mobile {
    padding: 0;
    background: none;
    margin-bottom: 10px;

    .mobile-search-wrapper {
      display: flex;
      padding: 0 20px;

      .ant-form-item {
        width: calc(100% - 42px);
        margin-bottom: 0;
      }

      .filter-icon {
        padding: 0;
        margin-left: 10px;

        & > span > img {
          margin: 0;
        }
      }
    }

    .mobile-filter {
      padding: 10px 20px;
      background-color: $background-gray;
      margin-top: 10px;

      .ant-form-item {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.invite-complete-wrapper {
  //margin: 30px 90px 70px;
  margin: auto 90px;
  //margin-top: auto;
  height: 100%;
  /* min-height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;

  .notify {
    display: flex;
    gap: 15px;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #0C758C;
    padding: 10px 0;
    background: #E8F0F2;

    &-error {
      border-color: #8C0C0C;
      background: #FDF5F5;
    }
  }

  .form {
    margin-top: 44px;

    &-title {
      color: #0C758C;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &-subtitle {
      font-size: 16px;
      font-weight: bold;
      color: #0B404B;
      margin-bottom: 20px;
    }

    &-button {
      display: flex;
      margin: 35px auto 0;
    }
  }

  .hr {
    background: #E8F0F2;
    width: 100%;
    height: 1px;
    margin: 25px 0;
  }
}

@import "styles/colors";

.App {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  background-color: $primary-background-green;

  .App-inner-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-background-green;
    flex-direction: column;
  }

  &.bgNone {
    background: none;
  }

  .bgNone {
    background: none;
  }

  &.authenticated {
    background-color: $white;

    .App-inner-content {
      background-color: $white;
    }

    &.mobile {
      .App-inner-content {
        height: 100vh;
      }
    }
  }

  &.bg {
    background-color: $white;
  }

  &.mobile {
    height: initial;
    min-height: 100vh;
  }
}

@import "styles/colors.scss";

.welcome-page-wrapper {
  background: none;
  width: 100vw;
  padding: 0 110px;
  display: block;

  h1 {
    font-size: 32px;
    font-weight: 700;
    color: $white;
    padding: 10px 0 35px 20px;
    margin: 0;
  }

  .group {
    display: flex;
    justify-content: space-evenly;

    &-right {
      margin-right: 110px;
      margin-top: 80px;
    }

    &-container {
      display: flex;
      align-items: flex-end;
      gap: 60px;
      margin-top: 72px;
      color: $note-message-green;
    }

    &-footer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  .header {
    display: flex;
    z-index: 10;
    margin: 28px 36px 0 20px;
  }

  .login-button {
    margin-left: auto;
    color: $white;
    font-weight: 400;
    font-size: 16px;

    img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      margin-bottom: -2px;
    }
  }

  .form-wrapper {
    background-color: $white;
    padding: 30px 50px 40px 50px;
    border-radius: 20px;
    width: fit-content;
    flex: none;
  }

  &.mobile {
    padding: 0 10px;

    h1 {
      width: 100%;
      padding-left: 0;
      text-align: center;
    }

    .form-wrapper {
      padding: 20px;
      margin-bottom: 20px;

      .case-form {
        width: 100%;
      }
    }
  }
}

.info {
  margin-top: 10px;

  &-link {
    color: #0C758C;
    cursor: pointer;
    text-decoration: underline;
  }
}

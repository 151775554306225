@import "colors";

.ant-pagination {
  color: $primary-background-green;

  .ant-pagination-item {
    a {
      color: $primary-background-green;
    }

    &:hover {
      background-color: $background-gray;
      a {
        color: $primary-button-green;
      }
    }

    &.ant-pagination-item-active {
      border-color: $primary-button-green;

      a {
        color: $primary-button-green;
      }
    }
  }

  .ant-pagination-jump-next, .ant-pagination-jump-prev {
    &:hover {
      .ant-pagination-item-link-icon {
        color: $primary-button-green;
      }
    }
  }

  .ant-pagination-options {
    .ant-select {
      &:hover, &.ant-select-open, &.ant-select-focused {
        .ant-select-selector {
          border-color: $input-border-hover-green !important;
        }
      }

      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: 0 0 0 2px $input-border-shadow-green !important;
        }
      }
    }
  }
}

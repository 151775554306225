@import "styles/colors.scss";

.contract-info-sla-modal {
  width: 100%;

  .sla {
    &-modal {
      min-height: 476px;
      width: 500px;
      margin: auto;

      .textarea-wrapper {
        textarea {
          resize: none;
        }
      }

      .content {
        overflow-y: auto;
        max-height: 396px;
        margin-bottom: 40px;
        white-space: pre-line;

        &-empty {
          color: #929EA0;
        }
      }
    }

    &-mini {
      &-title {
        margin: 20px auto 10px;
        line-height: 22px;
        font-size: 20px;
        font-weight: bold;
        color: $primary-button-green;
        text-align: center;
      }

      &-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;
        margin-bottom: 20px;

        & > button {
          width: 100px;
        }
      }
    }

    &-header {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 15px;

      &-title {
        font-size: 20px;
        font-weight: bold;
        color: $primary-button-green;
      }

      &-img {
        width: 18px;
        cursor: pointer;
      }
    }

    &-footer {
      margin: 20px auto;
      text-align: center;

      & > button {
        width: 100px;
      }
    }
  }
}
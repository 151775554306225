@import "styles/colors.scss";

.add-file-form-wrapper {
  .back-button {
    margin-bottom: 25px;

    img {
      margin-right: 10px;
      margin-bottom: -1px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      color: $primary-background-green;
    }
  }

  .add-file-form {
    max-width: 400px;
    padding: 0 25px;

    .uploaded-file {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 20px;

      p {
        margin: 0;
      }

      .uploaded-file-info {
        margin-left: 10px;

        .uploaded-file-name {
          font-weight: 600;
          color: $note-message-green;
        }

        .uploaded-file-size {
          display: flex;

          .uploaded-file-title {
            font-weight: 600;
            color: $link-button-green;
            font-size: 12px;
            margin-right: 10px;
          }

          .uploaded-file-value {
            font-weight: 400;
            color: $note-message-green;
            font-size: 12px;
          }
        }
      }

      .remove-file-button {
        margin-left: auto;
      }
    }

    .upload-file-wrapper {
      .add-file-form-drag {
        .ant-upload-drag {
          background-color: $background-gray;

          &:hover {
            border-color: $input-border-hover-green;
          }
        }

        .ant-upload-btn {
          padding: 40px 0;

          .upload-title {
            margin: 0;
            color: $note-message-green;
          }
        }

        .ant-upload-list {
          display: none;
        }
      }
    }

    .switch-info-wrapper {
      margin-bottom: 5px;

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;

        .switch-info {
          color: $primary-background-green;
          margin: 0 0 0 10px;
        }
      }

      &.off {
        .ant-form-item-control-input-content {
          .switch-info {
            color: $note-message-green;
          }
        }
      }
    }

    .buttons-wrapper {
      display: flex;
      margin-top: 35px;

      .ant-btn {
        margin-right: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
}

@import "styles/colors.scss";

.products-table {
  .ant-table-cell {
    padding: 6px 15px !important;

    .ant-input {
      transition: all 300ms ease-in-out;

      &.error {
        border-color: $warning-red-text;
      }
    }

    .ant-select {
      .ant-select-selector {
        transition: all 300ms ease-in-out;
      }

      &.error {
        .ant-select-selector {
          border-color: $warning-red-text;
        }
      }
    }
  }
}

@import "styles/colors.scss";

.notifications-wrapper {
  display: flex;

  button {
    margin: 0 !important;
  }

  .ant-spin {
    padding-top: 20px;
    margin-right: 10px;
  }

  .notifications-bell {
    margin-top: 12px;
    img {
      margin: 0 !important;
    }
  }

  .notifications-count {
    background-color: $primary-button-green;
    color: $white;
    font-size: 12px;
    line-height: 12px;
    padding: 4px 8px;
    border-radius: 10px;
    margin: 3px 0 0 -15px;
    height: 20px;
  }
}

.notes-popover-wrapper {
  padding: 18px;
  min-width: 325px;

  .notifications-inner-wrapper {
    max-height: calc(100vh - 300px);
    overflow-y: scroll;
  }

  .empty-title {
    color: $gray;
    text-align: center;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: $primary-background-green;
    margin: 0 0 20px 0;
    display: flex;
    align-items: center;

    button {
      margin-left: auto;
      margin-right: -15px;
    }
  }

  .note-item {
    display: flex;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .note-item-icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: $link-button-green;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    .note-item-description {
      width: 100%;

      .note-item-info {
        display: flex;

        .note-item-sender {
          color: $primary-background-green;
          font-size: 14px;
          font-weight: 500;
          margin: 0;
        }

        .note-item-timestamp {
          margin: 0 0 0 auto;
          color: $light-timestamp-green;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .note-item-message {
        color: $note-message-green;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        white-space: nowrap;

        b {
          color: $link-button-green;
          font-weight: 600;
        }
      }
    }
  }
}

@import "styles/colors.scss";

.section {
  &-info {
    margin-bottom: 10px;
    border: 1px solid $border-gray;
    padding: 10px;
    border-radius: 10px;
    position: relative;
  }
}

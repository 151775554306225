@import "styles/colors.scss";

.section {
  border: 1px solid $light-gray;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.subtitle {
  color: $gray;
}

.logo {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 30px;

  &-img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    border: 1px solid $border-gray;
  }
}

.noLogo {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 5px;
  border: 1px solid $border-gray;

  &-text {

  }
}

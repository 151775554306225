@import "styles/colors.scss";

.contact-equipment-table-form {
  overflow-y: scroll;
  max-height: 689px;
}

.contact-equipment-table-header {
  height: 30px;
  background: $background-gray;
  border: 1px solid $border-gray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
  padding: 0 15px 2px 45px;
  color: $table-header-green;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  &-item {
    width: 100%;
    padding: 5px 15px 4px 15px;
    font-size: 12px;
  }
}

.contact-equipment-table-form {
  border: 1px solid $border-gray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.contact-equipment-form-item {
  display: flex;
  gap: 10px;
  border-top: 1px solid $border-gray;
  padding: 7px 15px;

  &-id {
    display: flex;
    align-items: center;
    min-width: 16px;
  }

  & > div {
    margin: 0;
  }
}
@import "styles/colors.scss";

.contract-info-view {
  width: 100%;
  display: flex;
  gap: 10px;

  & > div {
    flex: 1;
    width: 100%;
  }

  &-title {
    color: $dark-green;
    font-size: 16px;
    font-weight: 600;
  }
}

.contract-info-view-group {
  display: flex;
  gap: 10px;
}

.contract-info-view-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  width: 100%;

  &-title {
    color: $table-header-green;
    width: 80px;
  }

  &-divider {
    color: $primary-button-green;
  }

  .sla-button {
    img {
      margin-left: 4px;
    }
  }

  .link {
    color: $primary-button-green;
    cursor: pointer;
    text-decoration: underline;
  }
}

.contract-info-view-button {
  img {
    margin-left: 4px;
    margin-bottom: -2px;
  }
}

.contract-info-view-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;

  img {
    margin-right: 0 !important;
  }

  &-more {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.popover {
  &-devider {
    background: $border-gray;
    height: 1px;
    margin: 4px 15px;
  }

  &-item {
    padding: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;

    &-disabled {
      color: #929EA0;
      pointer-events: none;
    }
  }
}

.export {
  display: flex;
  justify-content: space-between;
  position: relative;

  &:hover {
    background: $light-blue;
    cursor: pointer;
  }

  &-sub-menu {
    background: white;
    position: absolute;
    top: 0;
    right: 150px;
  }
}

.publish {

  &:hover {
    background: $light-blue;
    cursor: pointer;
  }
}

.terminate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 107px;
  color: #4B0B0B;

  &:hover {
    background: $light-blue;
    cursor: pointer;
  }
}

.view-only {
  color: $note-message-green;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 34px;
  font-weight: 500;
}

.contract-info-form-wrapper {
  display: flex;
  gap: 108px;
}

.contract-info-form {
  width: 100%;
  flex: 1;

  &-form {
    width: 100%;
    display: flex;
    gap: 20px;

    & > div {
      width: 100%;
    }

    .ant-form-item-label label{
      width: 142px;
    }
  }

  &-buttons {
    display: flex;
    gap: 10px;

    & > button {
      width: 100px;
    }
  }
}

.alias-add {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $note-message-green;
  cursor: pointer;
}

.alias-edit {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $link-button-green;
  cursor: pointer;
}

.alias-input-mini {
  background-color: $light-blue;
  border-radius: 3px;
  display: flex;

  input {
    background: none;
    color: $link-button-green;
  }
}

.information-view {
  border: 1px solid $border-gray;
  border-radius: 3px;
  width: 280px;
  height: 70px;
  padding: 4px 11px;
}

@import "styles/colors.scss";

.general-settings {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;

  .section {
    width: 544px;
    padding: 20px;
    border: 1px solid $light-gray;
    border-radius: 10px;
    height: fit-content;

    &-title {
      color: $nav-link-hover-green;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
    }

    &-text-color {
      color: $gray;
    }

    &-text {
      color: $gray;
      margin-bottom: 30px;
    }

    &-arrow-down {
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;

      border-top: 20px solid #f00;
    }

    &-arrow-up {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-bottom: 5px solid black;
    }

    &-list {
      border-radius: 10px;
      border: 1px solid $border-gray;

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 36px;
        padding: 0 15px;
        box-sizing: content-box;

        &:not(:nth-child(1)) {
          border-top: 1px solid $border-gray;
        }

        &-group {
          display: flex;
          flex-direction: column;
          gap: 4px;
          padding-right: 10px;
        }

        &-control {
          display: block;

          &-up {
            padding: 0;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-bottom: 10px solid $link-button-green;
            cursor: pointer;
            opacity: 0.7;

            &:hover {
              opacity: 1;
            }
          }

          &-down {
            padding: 0;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-top: 10px solid $link-button-green;
            cursor: pointer;
            opacity: 0.7;

            &:hover {
              opacity: 1;
            }
          }
        }

        &-badge {
          margin: 8px 10px 8px 0;
          width: 84px;
          text-align: center;
          border-radius: 5px;
          border: 1px solid $nav-link-active-green;
          color: $link-button-green;
          background: $light-blue;
          position: relative;
          padding: 2px 6px;
          cursor: default;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        &-title {
          flex: 1;
          line-height: 22px;
          margin: 8px 10px;
          position: relative;

          &:before {
            position: absolute;
            content: "";
            width: 1px;
            background: $border-gray;
            height: 100%;
            left: -10px;
            top: 0;
          }
        }

        &-info {
          background: $light-gray;
          width: 70px;
          border-radius: 12px;
          height: 22px;
          line-height: 18px;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }

        &-button {
          display: flex;
          align-items: center;

          & > span > img {
            margin-right: 2px;
            margin-bottom: 2px;
          }
        }
      }

      &-button {
        width: 100%;
        margin-top: 10px;

        & > img {
          margin-right: 4px;
          margin-bottom: -1px;
        }
      }
    }

    &-add {
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 10px 0 2px;
    }

    &-edit {
      height: 42px;
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 0 4px;
      border-top: 1px solid $border-gray;
    }
  }
}

.delete-category-modal {
  .delete-category-content {
    padding: 10px 20px 20px 20px;
    width: 320px;

    h4 {
      font-size: 20px;
      margin: 0 0 20px 0;
      font-weight: 600;
      color: $primary-button-green;
      text-align: center;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .ant-btn {
        width: 90px;
        height: 30px;
        padding-top: 3px;
        padding-bottom: 4px;
      }
    }
  }
}

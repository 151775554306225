@import "styles/colors.scss";

.navbar-wrapper {
  background-color: $primary-background-green !important;
  width: 200px;

  .navbar-logo {
    height: 41px;
    display: flex;
    align-items: center;

    & > img {
      margin-left: 16px;
    }
  }

  .navbar-label {
    margin: 0 16px 6px;
    padding: 17px 0;
    border-top: 1px solid $nav-link-hover-green;
    border-bottom: 1px solid $nav-link-hover-green;
    color: $white;
    font-weight: bold;
    cursor: default;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .nav-wrapper {
    .divider {
      width: 130px;
      height: 1px;
      background-color: $border-gray;
      margin: 10px 0 10px 35px;
    }

    .nav-item {
      margin: 5px 10px 5px 6px;
      padding: 8px 10px;
      color: $white;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      position: relative;

      &-child {
        padding: 8px 10px 8px 30px;

        &-count {
          min-width: 15px;
          padding: 0 4px;
          text-align: center;
          height: 15px;
          border-radius: 10px;
          background: #426167;
          font-size: 10px;
          margin-left: 7px;
          margin-top: 2px;
        }
      }

      &-dot {
        background: $input-border-hover-green;
        width: 7px;
        height: 7px;
        border-radius: 7px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 7px;
      }

      &-control {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 0!important;
        padding: 12px;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }
      }

      img {
        margin-right: 10px;
      }

      .clients-count-value {
        margin: 0 0 0 7px;
        color: $white;
        font-size: 12px;
        line-height: 18px;
        padding: 1px 6px 0 6px;
        background-color: $primary-button-green;
        position: absolute;
        right: 15px;
        border-radius: 8.5px;
      }

      &:hover {
        background-color: $nav-link-hover-green;
      }

      &.active {
        color: $nav-link-active-green;
      }
    }
  }
}

.footer-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0 0 env(safe-area-inset-bottom) 0 !important;
  background-color: $white !important;

  .footer-nav-wrapper {
    display: flex;

    @media (max-width: 720px) {
      justify-content: space-evenly;
    }

    .nav-item {
      display: flex;
      flex-direction: column;
      width: 25%;
      align-items: center;
      height: 53px;
      color: $note-message-green;

      img {
        width: 24px;
        height: 24px;
        margin-top: 8px;
      }

      &.active {
        color: $primary-button-green;
        font-weight: 500;
      }
    }
  }
}

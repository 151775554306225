.create-case-form {
  width: fit-content !important;

  .ant-modal-content {
    border-radius: 20px;
  }

  .case-form {
    width: 100%;
    max-width: 420px;
  }
}

@import "styles/colors.scss";

.mobile-oops-page-wrapper {
  padding: 62px 55px 60px 55px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: initial !important;

  img {
    width: 196px;
    height: 343px;
  }

  p {
    color: $light-timestamp-green;
    font-size: 20px;
    font-weight: 500;
    margin: 47px 0 42px 0;
    text-align: center;
  }

  .ant-btn {
    width: 100%;
    height: 40px;
  }
}

@import "styles/colors.scss";

.current-case-page-wrapper {
  .back-button {
    margin-bottom: 25px;

    img {
      margin-right: 10px;
      margin-bottom: -1px;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      color: $primary-background-green;
    }
  }

  .cards-wrapper {
    display: flex;
  }
}

@import "styles/colors.scss";

.cases-table {
  &.mobile {
    .ant-table {
      border: none !important;

      .ant-table-thead {
        .ant-table-cell {
          background: none;

          border: none !important;
        }
      }

      .ant-table-tbody, .ant-table-thead {
        .ant-table-row, tr {
          .ant-table-cell {
            &:first-child {
              padding-left: 20px;
            }

            &:last-child {
              padding-right: 20px;
            }
          }
        }
      }

      .ant-table-tbody {
        .ant-table-row {
          border: 2px solid red !important;
          margin-bottom: 10px;
        }
        .ant-table-cell {
          padding: 7px 15px;
          border: none !important;
        }
      }

      .expanded-menu {
        margin: 7px 0;

        .expanded-menu-title {
          color: $table-header-green;
          width: 105px;
        }

        .expanded-menu-row {
          display: flex;
        }
      }
    }
  }
}

.rowClassName1 {
  //background-color: cadetblue;
  border-radius: 10px;
  border-color: chartreuse;
  border-width: 3px;
  border-style: solid;
}

.rowClassName1 td:first-child {
  border-radius: 10px;
  //border-top-left-radius: 10px;
  //border-bottom-left-radius: 10px;
  margin-bottom: 20px !important;
}

.rowClassName1 td:last-child {
  border-radius: 10px;
  //border-top-right-radius: 10px;
  //border-bottom-right-radius: 10px;
  margin-bottom: 20px !important;
}

.cases-container {
  padding: 12px 20px;
}

.case-item {
  border: 1px solid $border-gray;
  margin-bottom: 3px;
  border-radius: 5px;
  padding: 7px;

  &-title {
    display: flex;
    align-items: center;
    gap: 10px;

    &-text {
      color: $primary-background-green;
      font-weight: 500;
      line-height: 22px;
    }

    &-icon {
      margin-top: 2px;
    }

    &-date {
      color: $note-message-green;
      font-weight: 500;
      font-size: 12px;
    }

    &-tooltip {
      display: flex;
      margin-left: auto;
    }
  }

  &-content {
    color: $primary-background-green;
    margin: 5px 0;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 5px;

    &-icon {
      width: 10px;
    }
  }
}

.default-footer-wrapper {
  &.mobile {
    padding: 0 0 calc(60px + env(safe-area-inset-bottom)) 0 !important;
  }
}

.ant-pagination {
  margin: auto;
  //width: 100%;
  //min-width: 100%;

  //& > li {
  //  width: 28px !important;
  //  height: 28px !important;
  //  min-width: 28px;
  //}
  //
  //.ant-pagination-item {
  //  width: 28px !important;
  //  height: 28px !important;
  //  min-width: 28px;
  //}
}

@import "colors";

.ant-form-item-row {
  .ant-form-item-label {
    label {
      color: $primary-background-green;

      &.ant-form-item-required {
        &:before {
          display: none !important;
        }

        &:after {
          display: inline-block;
          margin-inline-end: 4px;
          color: $primary-button-green;
          font-size: 14px;
          font-family: Roboto, serif;
          line-height: 1;
          content: "*";
          visibility: visible;
        }
      }
    }
  }

  .ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-input, .ant-input-affix-wrapper {
          color: $primary-background-green;

          &:hover {
            border-color: $input-border-hover-green;
          }

          &:focus {
            border-color: $input-border-hover-green;
            box-shadow: 0 0 0 2px $input-border-shadow-green;
          }
        }

        .ant-input-password, .ant-picker {
          input {
            color: $primary-background-green;
          }

          &:hover {
            border-color: $input-border-hover-green;
          }

          &:focus-within {
            border-color: $input-border-hover-green;
            box-shadow: 0 0 0 2px $input-border-shadow-green;
          }

          .ant-picker-active-bar {
            background-color: $input-border-hover-green;
          }
        }

        .ant-radio-wrapper {
          span {
            &:last-child {
              color: $primary-background-green;
            }
          }

          &:hover {
            .ant-radio-inner {
              border-color: $primary-button-green;
            }
          }

          .ant-radio {
            &.ant-radio-checked {
              .ant-radio-inner {
                border-color: $primary-button-green;
                background-color: $primary-button-green;
              }
            }
          }
        }

        .ant-select {
          .ant-select-selector {
            color: $primary-background-green;
          }

          &:hover {
            .ant-select-selector {
              border-color: $input-border-hover-green;
              color: $primary-background-green;
            }
          }

          &.ant-select-focused {
            .ant-select-selector {
              box-shadow: 0 0 0 2px $input-border-shadow-green;
              border-color: $input-border-hover-green;
            }
          }
        }
      }
    }
  }
}

.ant-input, .ant-input-affix-wrapper {
  color: $primary-background-green;

  &:hover {
    border-color: $input-border-hover-green !important;
  }

  &:focus, &-focused {
    border-color: $input-border-hover-green !important;
    box-shadow: 0 0 0 2px $input-border-shadow-green !important;
  }
}

.ant-select {
  .ant-select-selector {
    .ant-select-selection-item {
      color: $primary-background-green;
    }

    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          color: $primary-button-green;
          background-color: $light-blue;
          border-radius: 2px;
          border: none;

          .ant-select-selection-item-remove {
            display: flex;

            span {
              svg {
                width: 12px;
                height: 12px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $note-message-green;
                padding: 2.5px;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

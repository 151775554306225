@import "styles/colors";

.case-comments-wrapper {
  width: calc(100% - 315px);

  &.mobile {
    width: 100%;
    height: 100%;
  }

  .ant-tabs-nav {
    margin-bottom: 20px !important;

    .ant-tabs-tab {
      .clients-count-value {
        background-color: $light-gray !important;
        color: $table-header-green !important;
        transition: all 200ms ease-in-out;
      }

      &.ant-tabs-tab-active {
        .clients-count-value {
          background-color: $primary-button-green !important;
          color: $white !important;
        }
      }
    }
  }

  .comment-wrapper {
    display: flex;
    margin-bottom: 15px;

    p {
      margin: 0;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: $link-button-green;
      margin-right: 15px;
    }

    .comment-info-wrapper {
      width: calc(100% - 55px);

      .comment-title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        .user-name {
          font-weight: 500;
          color: $primary-background-green;
          line-height: 22px;
          margin-right: 10px;
        }

        .date {
          font-size: 12px;
          line-height: 22px;
          color: $table-header-green;
          margin-right: 7px;
        }

        .time {
          font-size: 12px;
          line-height: 22px;
          color: $table-header-green;
        }
      }

      .comment-content-wrapper {
        background-color: $light-blue;
        padding: 10px;
        border-radius: 5px;

        &.full {
          .comment-text {
            max-height: 500px;
            overflow: scroll;
          }

          .show-more-button {
            img {
              transform: rotate(180deg);
            }
          }
        }

        .comment-text {
          color: $primary-background-green;
          max-height: 170px;
          overflow: hidden;
          line-height: 17px;
          transition: max-height 400ms ease-in-out;
        }

        .show-more-button-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;

          .show-more-button {
            font-weight: 400;

            img {
              margin-left: 10px;
              transition: all 400ms ease-in-out;
            }
          }
        }

        .attaches-wrapper {
          display: flex;

          .file-card-wrapper {
            margin-top: 10px;
            width: 80px;
            height: 80px;
            margin-right: 10px;

            .file-card {
              width: 80px;
              height: 80px;
            }

            .description {
              display: none;
            }
          }
        }

        .download-all-button {
          display: flex;
          align-items: center;
          padding-left: 7px;
          margin-top: 6px;

          span {
            color: $primary-button-green !important;
            font-weight: 400;
          }

          b {
            font-weight: 400;
            color: $table-header-green;
            margin-left: 7px;
          }
        }
      }
    }
  }

  .add-comment-wrapper {
    margin-top: 32px;
    display: flex;

    .add-comment-icon {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: $link-button-green;
      margin-right: 15px;
    }

    .add-comment-input-wrapper {
      width: calc(100% - 55px);

      .textarea-wrapper {
        padding: 10px;
        border: 1px solid $border-gray;
        border-radius: 5px;
        color: $primary-background-green;
        margin-top: 1px;

        .ant-input {
          min-height: initial;
          padding: 0;
          resize: none;
          height: 16px;
          line-height: 16px;
          border: none !important;
          box-shadow: none !important;
          border-radius: 0;

          &:hover {
            border: none;
          }

          &.ant-input-affix-wrapper-focused {
          }

          &::placeholder {
            color: $table-header-green;
          }
        }

        .attach-button {
          color: $link-button-green;
          display: flex;
          align-items: center;
          font-weight: 400;
          padding: 4px;
        }
      }

      .send-button {
        margin-top: 10px;
        padding: 6px 30px;
        height: 35px;
        box-shadow: none;
      }

      &.active {
        .textarea-wrapper {
          height: 116px;

          .ant-input {
            height: 64px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}

@import "styles/colors.scss";

.user-management-table {
  .ant-table {
    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          .edit-info-input {
            border: none;
            height: 32px;
            background-color: $light-blue;
            display: flex;
            color: $link-button-green;
            max-width: 600px;
            margin-left: -11px;
            line-height: 18;
          }

          .edit-info-select {
            margin-left: -11px;

            .ant-select-selector {
              border: none;
              height: 32px;
              background-color: $light-blue;
              display: flex;
              width: 123px;

              .ant-select-selection-item {
                color: $link-button-green;
                line-height: 32px;
              }
            }

            &.extra-width {
              width: 100%;
              .ant-select-selector {
                width: 100%;
              }
            }
          }

          .buttons-wrapper {
            display: flex;
            justify-content: right;

            .ant-btn {
              margin-right: 10px;

              &:last-child {
                margin-right: 0;
              }

              &.icon-button {
                display: flex;
                justify-content: center;
                padding-left: 5px;

                img {
                  margin-top: 3px;
                  height: 18px;
                  width: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.user-accept-modal-wrapper {
  .ant-modal-content {
    border-radius: 20px;
    padding: 30px 40px;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      .modal-title {
        margin: 0 0 20px 0;
        font-size: 16px;
        font-weight: 400;
        color: $primary-background-green;
        line-height: 22px;

        span {
          font-weight: 600;
          color: $primary-button-green;
        }
      }

      .modal-info {
        display: flex;
        margin-bottom: 40px;

        p {
          margin: 0;
        }

        .customer {
          color: $table-header-green;
        }

        .role {
          margin-left: auto;
          color: $link-button-green;
        }
      }

      .question {
        width: 100%;
        text-align: center;
        margin: 0 0 30px 0;
        font-size: 20px;
        line-height: 22px;
        font-weight: 600;
        color: $primary-button-green;
      }

      .action-wrapper {
        display: flex;
        justify-content: center;

        .ant-btn {
          width: 90px;
          height: 30px;
          line-height: 20px;

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@import "styles/colors.scss";

.cases-mobile {
  //&-container {
  //  display: flex;
  //  flex-direction: column;
  //  padding: 10px 20px;
  //  height: 100%;
  //  width: 100%;
  //}

  &-title {
    font-size: 20px;
    font-weight: 600;
    color: $primary-button-green;
    padding: 10px 20px;
  }
}

@import "styles/colors.scss";

.equipment-item {
  &-title {
    display: flex;
    align-items: center;
    gap: 10px;

    &-text {
      color: $primary-background-green;
      font-weight: 500;
      line-height: 22px;
    }

    &-icon {
      margin-top: 2px;
    }

    &-date {
      color: $note-message-green;
      font-weight: 500;
      font-size: 12px;
    }

    &-tooltip {
      display: flex;
      margin-left: auto;
    }
  }

  &-button {
    margin: 16px 0 20px;
  }

  &-content {
    color: $primary-background-green;
    margin: 5px 0;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 5px;

    &-icon {
      width: 10px;
    }
  }
}

.default-footer-wrapper {
  &.mobile {
    padding: 0 0 calc(60px + env(safe-area-inset-bottom)) 0 !important;
  }
}

.ant-pagination {
  margin: auto;
}

@import "styles/colors.scss";

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes moveVertical {
  0%, 100% {
    bottom: -10px;
  }

  30%, 70% {
    bottom: 90px;
  }

  40%, 60% {
    bottom: 110px;
  }

  20%, 80% {
    bottom: 60px;
  }

  50% {
    bottom: 120px;
  }
}

@keyframes moveHorizontal {
  from {
    left: calc(100% + 300px);
  }

  to {
    left: -300px;
  }
}

.not-found-page-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: $primary-background-green;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    color: $light-text;
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    margin-top: 91px;
  }

  h1 {
    color: $light-text;
    text-align: center;
    font-size: 270px;
    font-weight: 700;
    margin: 0 0 15px 0;
  }

  .ant-btn {
    height: 46px;
    width: 173px;
    font-size: 20px;
  }

  .tumbleweed {
    position: absolute;
    animation: rotation 4s infinite linear, moveVertical 2s infinite linear, moveHorizontal 10s infinite linear;
  }
}

@import "styles/colors.scss";

.contract-item-mobile {
  border: 1px solid $border-gray;
  margin-bottom: 3px;
  border-radius: 5px;
  padding: 7px;

  &-title {
    display: flex;
    align-items: center;
    gap: 10px;

    & > img:first-child {
      width: 16px;
      height: 16px;
    }

    &-text {
      color: $primary-background-green;
      font-weight: 500;
      line-height: 22px;
    }
  }

  &-footer {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.alias-value {
  color: $link-button-green;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0;
}

.add-title {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  color: $note-message-green;
}

.alias-input {
  margin: 10px 0;
}

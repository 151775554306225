@import "styles/colors.scss";

.file-icon-wrapper {
  position: relative;

  .link {
    position: absolute;
  }

  .title {
    position: absolute;
    margin: 0;
    color: $white;
    text-transform: capitalize;
    font-weight: 600;
  }

  &.small {
    width: 33px;
    height: 38px;

    .background {
      width: 33px;
      height: 38px;
    }

    .link {
      top: 12px;
      left: 8px;
      width: 16px;
      height: 16px;
    }

    .title {
      top: 8px;
      left: 11px;
      font-size: 16px;
    }
  }

  &.large {
    width: 63px;
    height: 72px;

    .background {
      width: 63px;
      height: 72px;
    }

    .link {
      top: 24px;
      left: 16px;
      width: 28px;
      height: 28px;
    }

    .title {
      top: 20px;
      left: 22px;
      font-size: 28px;
    }
  }
}

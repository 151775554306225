@import "styles/colors";

.file-card-wrapper {
  width: 100px;

  .file-card {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: #E49700;

    .black-back {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      transition: background-color 200ms ease-in-out;

      &:hover {
        background-color: #00000020;

        .ant-btn {
          display: flex;
        }
      }

      .ant-btn {
        height: 28px;
        width: 28px;
        display: none;
        align-items: center;
        justify-content: center;
        box-shadow: none;

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          img {
            margin-left: -2px;
          }
        }
      }
    }
  }

  .description {
    color: $dark-border-green;
    margin-top: 5px !important;
    text-align: center;
  }
}

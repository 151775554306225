@import "styles/colors.scss";

.loading {
  justify-content: center;
  align-items: center;
  display: flex;

  i {
    background-color: $white !important;
  }

  &.colored {
    i {
      background-color: $primary-button-green !important;
    }
  }
}

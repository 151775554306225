@import "styles/colors.scss";

.info-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 3px;
  width: 100%;

  &-title {
    color: $table-header-green;
    width: 115px;
    line-height: 22px;
  }

  &-accent {
    color: $dark-green;
    font-size: 16px;
    font-weight: 600;
  }
}

@import "colors";

.ant-btn {
  color: $primary-button-green;
  border-color: $primary-button-green;

  &:hover {
    color: $primary-background-green !important;
    border-color: $primary-background-green !important;
  }

  &.ant-btn-primary {
    color: $white;
    background-color: $primary-button-green;
    font-weight: 500;

    &:hover {
      background-color: $primary-background-green;
      color: $white !important;
    }

    &:disabled {
      background-color: $light-timestamp-green;
      border-color: $light-timestamp-green;

      &:hover {
        border-color: $light-timestamp-green !important;
      }
    }
  }

  &.ant-btn-link {
    color: $link-button-green;
    padding: 4px 0;
    border: none;

    &:hover {
      color: $primary-button-green;
    }
  }

  &.big-btn {
    padding: 7px 25px;
    font-size: 16px;
    height: initial;
    font-weight: 500;
  }

  &.ant-btn-text {
    color: $link-button-green;
    font-weight: 600;
    border: none;

    img {
      margin-right: 7px;
    }

    &:hover {
      color: $primary-button-green !important;
    }
  }
}

.ant-switch {
  background-color: $light-timestamp-green;

  &:hover {
    background-color: $note-message-green !important;
  }

  &.ant-switch-checked {
    background-color: $primary-button-green;

    &:hover {
      background-color: $primary-background-green !important;
    }
  }
}

@import "styles/colors.scss";

.list-equipment-table {
  .ant-table {
    .ant-table-body {
      .ant-table-row {
        &:hover {
          cursor: pointer;

          & > td {
            background-color: $light-blue !important;
          }
        }
      }

      .ant-table-cell {
        padding-top: 4px;
        padding-bottom: 4px;

        &.status-table-cell {
          padding: 0 0 0 15px;

          & > .status {
            display: flex;
          }
        }

        &.description-table-cell {
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100vw - 1058px);
          }

          &.admin {
            max-width: calc(100vw - 1218px);
          }

          &.customer {
            max-width: 170px;
          }
        }

        &.product-number-cell {
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 150px;
          }
        }

        &.serial-number-cell {
          a {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background: $light-blue;
            width: fit-content;
            padding: 0 4px;
            border-radius: 4px;
            color: $primary-background-green;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .ant-tag {
          padding-top: 0;
          padding-bottom: 0;
        }

        .contract-icon-wrapper {
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 5px;
          margin-left: 10px;

          &:hover {
            background-color: $light-blue;
          }
        }
      }
    }
  }
}

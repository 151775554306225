@import "styles/colors.scss";

.contract-create-table {
  padding-right: 30px;
  margin: 0 30px;

  .ant-table-body {
    overflow-y: hidden !important;
  }

  .ant-table {
    border: 1px solid $border-gray;
    border-radius: 10px;

    .ant-table-thead {
      .ant-table-cell {
        border-bottom: 1px solid $border-gray;
        border-top: none !important;
        color: $primary-background-green;

        &:first-child {
          border-start-start-radius: 10px !important;
        }

        &:last-child {
          border-start-end-radius: 10px !important;
        }

        &:before {
          display: none;
        }
      }
    }

    .ant-table-thead, .ant-table-tbody {
      .ant-table-cell {
        font-weight: 400;
        font-size: 14px;
        padding: 7px 15px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          border-top: 1px solid $border-gray !important;
          border-bottom: none !important;
          color: $primary-background-green;

          &:nth-child(2) {
            font-weight: 500;
          }

          &.ant-table-column-sort {
            background: none;
          }
        }

        &:first-child {
          .ant-table-cell {
            border-top: none !important;
          }
        }

        &:hover {
          .ant-table-cell, .ant-table-column-sort {
            background-color: $light-background-gray;

            &:first-child {
              border-start-start-radius: 0 !important;
              border-end-start-radius: 0 !important;
            }

            &:last-child {
              border-start-end-radius: 0 !important;
              border-end-end-radius: 0 !important;
            }
          }
        }
      }
    }
  }

  &-category {
    position: relative;

    &-select {
      width: 100%;
    }

    &-check {
      position: absolute;
      top: 6px;
      right: -50px;
    }
  }
}

@import "styles/colors.scss";

.snackbar {
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 10px 0;
  margin-top: 10px;

  &-info {
    border: 1px solid $border-blue;
    background-color: $expanded-row-background-green;
  }
}

@import "styles/colors.scss";

.invitations-table {
  .ant-table {
    border: 1px solid $border-gray;
    border-radius: 10px;

    .ant-table-thead {
      .ant-table-cell {
        border-bottom: 1px solid $border-gray;
        border-top: none !important;
        color: $table-header-green;
        cursor: pointer;

        &:first-child {
          border-start-start-radius: 10px !important;
        }

        &:last-child {
          border-start-end-radius: 10px !important;
        }

        &:before {
          display: none;
        }

        &.ant-table-column-sort {
          background-color: $background-gray;

          .ant-table-column-sorter {
            .ant-table-column-sorter-inner {
              .ant-table-column-sorter-up, .ant-table-column-sorter-down {
                &.active {
                  color: $primary-button-green !important;
                }
              }
            }
          }
        }
      }
    }

    .ant-table-thead, .ant-table-tbody {
      .ant-table-cell {
        font-weight: 400;
        font-size: 14px;
        padding: 5px 15px 4px 15px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          border-top: 1px solid $border-gray !important;
          border-bottom: none !important;
          color: $note-message-green;

          &.customer-table-cell {
            p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 170px;
            }
          }

          &.ant-table-column-sort {
            background: none;
          }

          p {
            margin: 0;

            &.active {
              font-weight: 600;
              color: $primary-background-green;

              &.thin {
                font-weight: 400;
              }
            }

            &.align-center {
              padding-left: 35px;
            }
          }
        }

        &:first-child {
          .ant-table-cell {
            border-top: none !important;
          }
        }

        &:last-child {
          .ant-table-cell {
            &:first-child {
              border-end-start-radius: 10px !important;
            }

            &:last-child {
              border-end-end-radius: 10px !important;
            }
          }
        }

        &:hover {
          .ant-table-cell, .ant-table-column-sort {
            background-color: $light-background-gray;
          }
        }

        .subject-cell {
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 22px;
            max-width: calc(100vw - 1000px);
          }
        }

        &.opened, &.expanded {
          background-color: $expanded-row-background-green;

          .subject-cell {
            p {
              height: initial;
              white-space: initial;
              text-overflow: initial;
            }
          }
        }
      }

      .ant-table-expanded-row, .expanded {
        background-color: $expanded-row-background-green;

        .ant-table-cell {
          border-top: none;
          background: none;
        }
      }
    }
  }
}

@import "styles/colors.scss";

.contract-info-wrapper {
  margin-bottom: 20px;

  .contract-info-item {
    display: flex;
    margin-bottom: 5px;

    p {
      margin: 0;
    }

    .contract-info-title {
      width: 130px;
      color: $table-header-green;
    }

    .contract-add-info-button {
      color: $primary-button-green;
      cursor: pointer;
    }

    .contract-info-value {
      color: $dark-green;

      .separator {
        margin: 0 10px;
        font-weight: 600;
        color: $nav-link-active-green;
      }

      img {
        margin: 0 0 -2px 15px;
        cursor: pointer;
      }
    }

    .contract-info-input-wrapper {
      width: 600px;

      .contract-info-input {
        border: none;
        height: 22px;
        background-color: $light-blue;
        border-radius: 3px;
        display: flex;
        color: $link-button-green;
        max-width: 600px;
        margin-bottom: 10px;
      }

      .contract-info-input-buttons {
        display: flex;

        .loading {
          margin-right: 10px;
        }

        .ant-btn {
          height: 22px;
          border-radius: 4px;
          padding: 0 10px 3px 10px;
          display: flex;

          span {
            font-size: 12px;
            line-height: 21px;
          }

          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@import "styles/colors.scss";

.section {
  border: 1px solid $light-gray;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 20px;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    & > span {
      font-size: 18px;
      line-height: 20px;
      font-weight: 600;
      color: $nav-link-hover-green;
    }
  }

  &-row {
    display: flex;
    gap: 90px;

    &-closer {
      display: flex;
      gap: 50px;
    }
  }

  &-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
    width: 300px;

    &-title {
      font-size: 16px;
      color: $table-header-green;
    }

    &-text {
      font-size: 16px;
      color: $nav-link-hover-green;
    }
  }

  &-buttons {
    display: flex;
    gap: 15px;
  }
}

.profile {
  &-edit-link {
    cursor: pointer;
    color: $link-button-green;
    height: 24px;
    width: fit-content;

    &:hover {
      text-decoration: underline;
    }

    img {
      margin-left: 4px;
      margin-bottom: -2px;
    }
  }
}

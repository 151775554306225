$primary-background-green: #0B404B;
$primary-button-green: #0C758C;
$link-button-green: #3E9CB0;
$nav-link-hover-green: #164F5A;
$nav-link-active-green: #81CADA;
$input-border-hover-green: #60B8CB;
$input-border-shadow-green: #49B5CD19;
$light-blue: #E8F0F2;
$light-timestamp-green: #B5CCD1;
$note-message-green: #769197;
$table-header-green: #929EA0;
$active-tag-background-green: #B3DDE7;
$expanded-row-background-green: #F0F7F9;
$dark-green: #071F23;
$dark-border-green: #426167;

$border-blue: #D7EAEE;

$white: #ffffff;
$light-gray: #efefef;
$background-gray: #F9F9F9;
$light-background-gray: #FAFAFA;
$border-gray: #D9D9D9;
$gray: #071f2344;
$light-text: #F0F7F9;
$secondary-light-grey: #B5CCD1;

$warning-red-background: #FFFAFA;
$warning-red-text: #8C0C0C;
$warning-red-text-secondary: #4B0B0B;

$warning-yellow: #E49700;

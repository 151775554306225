@import "styles/colors.scss";

.landing-v2-page-wrapper {
  background: $white;
  width: 100%;
  position: relative;
  overflow: hidden;

  .container {
    max-width: 1920px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    z-index: 10;
    margin: 28px 36px 0 20px;
  }

  .group {
    display: flex;
    flex-direction: row;
    gap: 70px;
    align-items: center;
    width: fit-content;
    margin: 110px auto 0;
    text-wrap: nowrap;

    @media (min-width: 1600px) {
      margin: 130px auto 0;
      gap: 110px;
      width: 80%;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
  }

  .promo {
    z-index: 10;
    width: 642px;

    @media (min-width: 1600px) {
      min-width: 50%;
    }
  }

  h1 {
    font-size: 48px;
    font-weight: bold;
    margin: 0;
    font-family: 'Roboto', sans-serif;

    @media (min-width: 1600px) {
      min-width: 50%;
      font-size: 4em;
    }
  }

  .subtitle {
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;
  }

  .form-wrapper {
    background: none;
    color: $primary-background-green;
    width: fit-content;
    position: relative;
  }

  .login-button {
    margin-left: auto;
    color: $white;
    font-weight: 400;
    font-size: 16px;

    img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      margin-bottom: -2px;
    }
  }
}

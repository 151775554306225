@import "styles/colors.scss";

//.list-equipment-table {
//  .ant-table {
//    border: none;
//    .ant-table-body {
//      .ant-table-cell {
//        padding: 4px 8px;
//
//        &.description-table-cell {
//          p {
//            overflow: hidden;
//            white-space: nowrap;
//            text-overflow: ellipsis;
//            max-width: calc(100vw - 1058px);
//          }
//
//          &.admin {
//            max-width: calc(100vw - 1218px);
//          }
//
//          &.customer {
//            max-width: 170px;
//          }
//        }
//
//        &.product-number-cell {
//          p {
//            overflow: hidden;
//            white-space: nowrap;
//            text-overflow: ellipsis;
//            max-width: 88px;
//          }
//        }
//
//        &.description-table-cell {
//          p {
//            overflow: hidden;
//            white-space: normal;
//            text-overflow: ellipsis;
//            max-width: 180px;
//          }
//        }
//
//        &.serial-number-cell {
//          p {
//            overflow: hidden;
//            white-space: normal;
//            text-overflow: ellipsis;
//            max-width: 120px;
//          }
//        }
//
//        .ant-tag {
//          padding-top: 0;
//          padding-bottom: 0;
//        }
//
//        .contract-icon-wrapper {
//          width: 22px;
//          height: 22px;
//          display: flex;
//          align-items: center;
//          justify-content: center;
//          border-radius: 5px;
//          margin-left: 10px;
//
//          &:hover {
//            background-color: $light-blue;
//          }
//        }
//      }
//    }
//  }
//}

.equipments-container {
  padding: 12px 20px;
}

.equipments-item {
  border: 1px solid $border-gray;
  margin-bottom: 3px;
  border-radius: 5px;
  padding: 7px;

  &-title {
    display: flex;
    align-items: center;
    gap: 10px;

    &-text {
      color: $primary-background-green;
      font-weight: 500;
      line-height: 22px;
    }

    &-icon {
      margin-top: 2px;
    }

    &-date {
      color: $note-message-green;
      font-weight: 500;
      font-size: 12px;
    }

    &-tooltip {
      display: flex;
      margin-left: auto;
    }
  }

  &-content {
    color: $primary-background-green;
    margin: 5px 0;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 5px;

    &-icon {
      width: 10px;
    }
  }
}

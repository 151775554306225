.settings-header {
  &-tabs {
    .ant-tabs-nav-list {
    }

    .ant-tabs-tab {
      min-width: 120px;
      justify-content: center;
    }
  }
}
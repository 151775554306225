@import "styles/colors.scss";

.downloads-table {
  .ant-table {
    border-radius: 10px;
    border: 1px solid $border-gray;
  }

  .ant-table-tbody {
    .ant-table-cell {
      cursor: pointer;
      color: $note-message-green;
      padding: 7px 15px;
      border-top: none !important;
      border-bottom: 1px solid $border-gray;

      &:first-child {
        padding-right: 5px;
        //border-left: 1px solid $border-gray !important;
      }

      &:last-child {
        //border-right: 1px solid $border-gray !important;
      }

      .downloads-title {
        .title {
          margin: 0;
          font-weight: 500;
          color: $note-message-green;
        }

        .file-name {
          margin: 0;
          color: $note-message-green;
          font-weight: 400;
        }
      }

      .deadline {
        font-weight: 500;
        color: $link-button-green;
        margin: 0;

        img {
          margin-right: 10px;
        }
      }

      .download-button {
        padding: 4px 10px;
        background-color: $link-button-green;
        border-color: $link-button-green;

        &:hover {
          background-color: $primary-button-green;
          border-color: $primary-button-green !important;
        }
      }
    }

    .ant-table-row {
      &:first-child {
        .ant-table-cell {
          border-top: 1px solid $border-gray !important;

          &:first-child {
            border-start-start-radius: 10px !important;
          }

          &:last-child {
            border-start-end-radius: 10px !important;
          }
        }
      }

      &:last-child {
        .ant-table-cell {
          border-bottom: none !important;

          &:first-child {
            border-end-start-radius: 10px !important;
          }

          &:last-child {
            border-end-end-radius: 10px !important;
          }
        }
      }

      &:hover {
        .ant-table-cell {
          border-start-start-radius: 0 !important;
          border-start-end-radius: 0 !important;
          border-end-start-radius: 0 !important;
          border-end-end-radius: 0 !important;
        }
      }
    }
  }
}

@import "styles/colors.scss";

.color-picker {
  position: relative;

  &-main {
    border-radius: 5px;
    border: 1px solid $border-gray;
    display: flex;
    padding: 6px;
    gap: 5px;
    cursor: pointer;

    &-active {
      background: $border-gray;
    }

    &-item {
      width: 16px;
      height: 16px;
      position: relative;
      border-radius: 3px;
      border: 1px solid $border-gray;
    }
  }

  &-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 120px;
    border: 1px solid $border-gray;
    border-radius: 4px;
    position: absolute;
    z-index: 10;
    background-color: #fff;
    top: 33px;
    padding: 6px;
    gap: 5px;

    &-item {
      width: 16px;
      height: 16px;
      position: relative;
      border-radius: 3px;
      border: 1px solid $border-gray;
      cursor: pointer;

      &-selected {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
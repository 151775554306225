@import "styles/colors.scss";

.current-equipment {
  width: 100%;
  display: flex;
  gap: 10px;

  &-column {
    flex: 1;
    width: 100%;
  }

  &-title {
    color: $dark-green;
    font-size: 16px;
    font-weight: 600;
  }

  &-group {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
}

@import "styles/colors.scss";

@keyframes afterClick {
  0% {
    box-shadow: 0 0 0 0 $gray;
  }

  50% {
    box-shadow: 0 0 10px 0 $gray;
  }

  100% {
    box-shadow: 0 0 0 0 $gray;
  }
}

.contact-info-card-wrapper {
  width: 285px;
  padding: 30px 25px;
  border-radius: 10px;
  border: 1px solid $gray;
  height: fit-content;

  .contact-info-title {
    font-weight: 500;
    margin: 0 0 15px 0;
    font-size: 15px;
    color: $nav-link-hover-green;
  }

  .info-wrapper {
    .info-item {
      margin-bottom: 10px;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        width: 60px;
        margin: 0;
        color: $table-header-green;
      }

      .value {
        margin: 0;
        color: $dark-green;
      }
    }
  }

  .task-link-wrapper {
    margin-top: 35px;

    .task-link-title {
      font-size: 15px;
      font-weight: 500;
      color: $nav-link-hover-green;
      margin: 0 0 15px 0;
    }

    .task-link-item {
      display: flex;
      align-items: center;
      padding: 5px 7px;
      background-color: $expanded-row-background-green;
      border: 1px solid $border-blue;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color ease-in-out 300ms;

      &:hover {
        background-color: $border-blue;
        border-color: $nav-link-active-green;
      }

      &:active {
        box-shadow: 0 0 2px 0 $gray;
      }

      .link-row {
        position: relative;
        height: 30px;

        &-img {
          margin-right: 4px;
        }
      }

      .link-icon-wrapper {
        position: relative;
        height: 30px;

        img {
          &:first-child {
            width: 26px;
            height: 30px;
            margin-right: 10px;
          }

          &:last-child {
            position: absolute;
            left: 6px;
            top: 10px;
          }
        }
      }

      .link {
        color: $primary-button-green;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none;
      }
    }
  }
}

@import "styles/colors.scss";

.case-item-mobile {
  border: 1px solid $border-gray;
  margin-bottom: 3px;
  border-radius: 5px;
  padding: 7px;

  &-title {
    display: flex;
    align-items: center;
    gap: 10px;

    &-text {
      color: $primary-background-green;
      font-weight: 500;
      line-height: 22px;
    }

    &-icon {
      margin-top: 2px;
    }

    &-date {
      color: $note-message-green;
      font-weight: 500;
      font-size: 12px;
    }

    &-tooltip {
      display: flex;
      margin-left: auto;
    }
  }

  &-content {
    color: $primary-background-green;
    margin: 5px 0;
  }

  &-info {
    display: flex;
    align-items: center;
    gap: 5px;

    &-icon {
      width: 10px;
    }
  }
}

@import "styles/colors";

.file-holder-wrapper {
  width: 100px;

  .file-holder {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    position: relative;

    &-icon {
      position: absolute;
      top: 14px;
      left: 13px;
    }

    .black-back {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      transition: background-color 200ms ease-in-out;
      position: relative;
      z-index: 1;

      &:hover {
        background-color: #00000020;

        .ant-btn {
          display: flex;
        }
      }

      .ant-btn {
        height: 28px;
        width: 28px;
        display: none;
        align-items: center;
        justify-content: center;
        box-shadow: none;

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          img {
            margin-left: -2px;
          }
        }
      }
    }
  }

  .description {
    color: $dark-border-green;
    margin-top: 5px !important;
    text-align: center;
  }
}

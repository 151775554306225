@import "styles/colors.scss";

.ant-tabs {
  .ant-tabs-nav {
    margin-bottom: 10px;
  }
  .ant-tabs-tab {
    padding: 5px 0 16px 0;

    .clients-count-value {
      margin: 0 0 0 7px;
      background-color: $primary-button-green;
      color: $white;
      padding: 0 5px;
      font-size: 12px;
      line-height: 22px;
      border-radius: 22px;
    }

    &:hover {
      .ant-tabs-tab-btn {
        color: $link-button-green;
      }
    }

    .ant-tabs-tab-btn {
      color: $table-header-green;
    }

    &-active {
      .ant-tabs-tab-btn {
        color: $primary-button-green !important;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: $primary-button-green;
  }
}

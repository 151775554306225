.storage-table {
  .ant-table-cell {
    padding: 4px 15px !important;
  }

  .labels-table-cell {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
    }
  }

  .name-table-cell {
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 400px;
    }
  }
}

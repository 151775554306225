@import "styles/colors.scss";

.app-header {
  display: flex;
  align-items: center;
  background: $light-background-gray;
  height: 42px !important;
  width: 100%;
  position: relative;
  z-index: 1000;
  padding: 0 24px;
  border-bottom: 1px solid $border-gray;

  .create-case-wrapper {
    margin-right: 12px;
    margin-left: auto;
    display: flex;
    margin-bottom: 2px;
  }

  .login-button {
    margin-left: auto;
    color: $white;
    font-weight: 400;
    font-size: 16px;

    img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      margin-bottom: -2px;
    }
  }

  .page-title {
    font-size: 24px;
    font-weight: 500;
    color: $primary-button-green;
    margin: 0;
    align-self: center;
    display: flex;
    align-items: center;

    &-contract {
      font-size: 14px;
      color: $dark-green;
      cursor: default;
      line-height: normal;
    }

    &-divider {
      margin: 0 10px;
      color: $table-header-green;
      cursor: default;
    }

    &-label {
      font-size: 14px;
      color: $table-header-green;
    }

    &-link {
      font-size: 14px;
      color: $table-header-green;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .status {
    background: $light-gray;
    color: #5C6263;
    border: 1px solid $border-gray;
    padding: 5px 15px;
    font-weight: bold;
    font-size: 14px;
    border-radius: 20px;
    line-height: 20px !important;
    margin-left: 25px;
    text-transform: capitalize;

    &-active {
      background: $light-blue;
      color: $primary-button-green;
      border-color: $primary-button-green;
    }
  }

  .app-logo {
    margin-top: 28px;
    margin-left: 4px;
  }

  &.mobile {
    .app-logo {
      width: 50%;
    }

    .user-menu-wrapper {
      margin-left: 0;
    }

    .notifications-wrapper {
      margin-left: 10px;
    }

    .create-case-button {
      margin-right: 0;
    }
  }
}

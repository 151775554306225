@import "styles/colors.scss";

.user-menu-wrapper {
  display: flex;
  cursor: pointer;
  margin-left: 24px;

  .loading {
    margin-right: 8px;
  }

  span {
    display: flex;
    align-items: center;

    .mock-avatar {
      background-color: $link-button-green;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 8px;
    }

    .user-name {
      margin: 0;
      color: $primary-background-green;
    }
  }
}

.user-menu-popover {
  top: calc(12px + env(safe-area-inset-top)) !important;
  left: calc(100% - 335px) !important;
  padding-top: 0 !important;

  .ant-popover-arrow {
    display: none;
  }

  &.mobile {
    left: 12px !important;
  }
}

.user-menu-popover-wrapper {
  padding: 8px 18px;
  width: 260px;

  .user-info {
    display: flex;
    align-items: center;
    padding: 10px 0 10px 5px;
    border-radius: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    .mock-avatar {
      background-color: $link-button-green;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      margin-right: 8px;
    }

    .user-name {
      margin: 0;
      font-weight: 500;
      color: $primary-background-green;
    }

    .info-icon {
      width: 16px;
      height: 16px;
      margin-left: 5px;
    }

    .info-title {
      font-weight: 400;
      font-size: 14px;
      color: $primary-background-green;
      margin: 0 0 0 12px;
      display: flex;
      width: 100%;
      align-items: center;

      .info-phone-input {
        .input-click {
          cursor: pointer;
        }
      }

      .ant-btn {
        padding: 2px 10px;
        width: 40px;
        margin-left: auto;

        img {
          width: 18px;
          height: 18px;
          padding: 4px 0 2px 0;
        }
      }
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: $light-gray;
      }
    }
  }
}

.default-page-wrapper {
  width: 100%;
  height: 100%;
  padding: 8px 24px 24px 24px;

  .default-footer-wrapper {
    position: fixed;
    bottom: 24px;
    display: flex;
    width: calc(100% - 248px);

    .ant-btn {
      img {
        margin-left: 10px;
      }
    }

    .ant-pagination {
      margin-left: auto;
    }

    &.mobile {
      position: initial;
      margin-top: 20px;
      padding: 0 20px 60px 20px;
      width: 100%;

      .ant-btn {
        img {
          margin: 0;
        }
      }
    }
  }

  &.mobile {
    padding: 8px 0 50px 0;
  }
}

@import "styles/colors.scss";

.case-form-new {
  width: 420px;

  .ant-form-item {
    margin-bottom: 15px;
  }

  .file-dropzone {
    background-color: $background-gray;
    border: 1px dashed $border-gray;
    border-radius: 4px;
    margin: 10px 0;
    justify-content: center;
    display: flex;

    p {
      font-size: 14px;
      color: $note-message-green;
    }
  }

  .add-email-button {
    margin-left: -12px;
    font-size: 14px;
    font-weight: 400 !important;
    margin-top: 5px;
  }

  .mg-btn-0 {
    margin-bottom: 0;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    color: $primary-background-green;
  }

  .with-count {
    .ant-input-suffix {
      margin-top: -70px;
      margin-right: -12px;
    }
  }

  .custom-radio-group {
    display: flex;

    .custom-radio-item {
      width: 102px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-right: 5px;
      cursor: pointer;
      border-radius: 22px;
      color: #929EA0;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .custom-radio-title {
        font-size: 12px;
        font-weight: 400;
        margin: 5px 0;
      }

      &-info {
        background: #DCE5E6;
        color: $nav-link-hover-green;
      }

      &-major {
        color: #D58000;
        background: #F9ECD9;
      }

      &-critical {
        color: $warning-red-text;
        background: #EEDBDB;
      }
    }
  }

  .form-with-button {
    margin-bottom: 0;

    .ant-form-item-label {
      label {
        display: flex;

        .add-file-label {
          margin-left: auto;
          cursor: pointer;
          color: $link-button-green;
        }
      }
    }
  }

  .serial-radio-group {
    background: $light-blue;
    display: flex;
    width: fit-content;
    border-radius: 4px;
    align-items: center;
    margin-bottom: 8px;

    &-item {
      color: $table-header-green;
      padding: 3px 12px;
      cursor: pointer;
      margin: 2px;

      &-active {
        border-radius: 4px;
        color: $primary-background-green;
        background: $white;
        cursor: default;
      }
    }
  }

  .files-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .files-item {
      display: flex;
      background-color: $light-blue;
      color: $primary-button-green;
      margin: 3px 5px 0 0;
      padding: 0 5px;
      align-items: center;
      border-radius: 2px;

      img {
        margin-right: 10px;
      }

      .cross-file {
        margin-left: 7px;
        margin-bottom: -4px;

        img {
          margin-right: 0;
        }
      }
    }
  }

  .user-info {
    background: $background-gray;
    padding: 7px 10px 15px;
    border: 1px solid #E8F0F2;
    border-radius: 4px;
  }

  .cc-info {
    margin-top: 15px;
  }

  .ant-select-selection-item {
    .cc-list {
      &-label {
        &-name {
          display: none;
        }
      }
    }
  }

  .case-equipment-wrapper {
    padding: 0 0 15px 16px;

    p {
      margin: 0;
    }

    .case-equipment-description {
      color: $note-message-green;
    }

    .case-equipment-client {
      color: $note-message-green;
      margin-bottom: 5px;
    }

    .case-equipment-dates-wrapper {
      display: flex;

      .case-equipment-dates {
        color: $primary-background-green;
        margin-right: 10px;
      }

      .ant-tag {
        background-color: $light-blue;
        border-color: $light-timestamp-green;
        border-radius: 5px;
        color: $note-message-green;
        font-weight: 500;
        font-size: 14px;
        padding: 0 0 2px 0;
        width: 84px;
        height: 22px;
        text-align: center;
        line-height: 20px;

        &.pending {
          background-color: $note-message-green;
          color: $white;
          border-color: $dark-border-green;
        }

        &.warranty {
          background-color: $light-blue;
          color: $link-button-green;
          border-color: $nav-link-active-green;
        }

        &.fix_time {
          background-color: $input-border-hover-green;
          color: $white;
          border-color: $link-button-green;
        }

        &.full_time {
          background-color: $active-tag-background-green;
          color: $primary-button-green;
          border-color: $input-border-hover-green;
        }

        &.next_business_day {
          background-color: $white;
          color: $link-button-green;
          border-color: $input-border-hover-green;
        }
      }
    }
  }

  .serial-number-error {
    padding: 4px 8px;
    margin-bottom: 24px;
    border: 1px solid rgba(255, 0, 0, 0.2);
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.1);
    color: red;
  }
}

.create-invite-button {
  margin: 0;
}

.create-invite-copy-button {
  background-color: #D7EAEE;
  border: 1px solid #D7EAEE;
  color: #0C758C;
  width: fit-content;
  padding: 6px 15px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 0 rgba(1, 73, 73, 0.28);

  & > img {
    margin-bottom: -3px;
    margin-right: 4px;
  }

  &:hover {
    border-color: #D7EAEE;
  }
}

.create-invite-form {
  max-width: 360px !important;
}

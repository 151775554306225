@import "styles/colors.scss";

.current-case-card-wrapper {
  width: calc(100% - 315px);
  margin-right: 30px;

  .current-case-header-wrapper {
    display: flex;
    margin-bottom: 35px;

    .current-case-icon-wrapper {
      margin-top: 3px;
      width: 40px;
      height: 40px;
      background-color: $light-text;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }


    .case-name-wrapper {
      max-width: calc(100% - 230px);

      .case-number {
        margin: 0 0 4px 0;
        font-size: 18px;
        font-weight: 500;
        color: $primary-button-green;
      }

      .case-name {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: $nav-link-hover-green;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .close-case-button {
      margin-left: auto;
      width: 130px;
      height: 35px;
      padding-top: 5px;
      padding-bottom: 6px;
    }
  }

  .current-case-info-wrapper {
    display: flex;
    margin-bottom: 25px;

    .current-case-info-column {

      &:first-child {
        width: 460px;
      }

      .current-case-info-item {
        display: flex;
        margin-bottom: 10px;

        .serial-number {
          background: $light-blue;
          width: fit-content;
          padding: 2px 4px;
          border-radius: 4px;

          &:hover {
            text-decoration: underline;
          }
        }

        .title {
          width: 160px;
          margin: 0;
          color: $table-header-green;
        }

        .value {
          margin: 0;
          color: $dark-green;
          max-width: 280px;

          span {
            color: $table-header-green;
          }
        }
      }
    }
  }

  .description-wrapper {
    margin-bottom: 25px;

    .title {
      color: $table-header-green;
      margin: 0 0 7px 0;
    }

    .value {
      margin: 0;
    }
  }

  .attachments-wrapper {
    .title {
      color: $primary-button-green;
      display: flex;
      align-items: center;

      .download-icon {
        margin-left: 10px;
        padding: 4px;
        border-radius: 2px;
        cursor: pointer;
        margin-bottom: -2px;
        transition: background-color 300ms;

        &:hover {
          background-color: $background-gray;
        }
      }
    }
  }

  .attachments-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  &.mobile {
    width: 100%;
    margin-right: 0;

    .current-case-header-wrapper {
      margin-bottom: 20px;
      flex-direction: column;

      .case-name-wrapper {
        max-width: 100%;

        .case-number {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 7px;
        }

        .case-name {
          font-size: 14px;
          font-weight: 400;
          color: $note-message-green;
          white-space: initial;
        }
      }

      .close-case-button {
        margin: 16px 0 0;
      }
    }

    .tab-buttons-wrapper {
      display: flex;
      margin-bottom: 20px;

      .ant-btn {
        height: 24px;
        padding: 3px 10px;
        display: flex;
        font-weight: 400;
        border: none;
        box-shadow: none;
        background-color: $light-blue !important;
        color: $dark-border-green !important;

        span {
          line-height: 18px;
          margin-top: -1px;
        }

        &:last-child {
          margin-left: 10px;
        }

        &:disabled {
          background-color: $nav-link-hover-green !important;
          color: $white !important;
        }
      }
    }

    .current-case-info-wrapper {
      flex-direction: column;
      margin-bottom: 20px;

      .current-case-info-column:first-child {
        margin-bottom: 10px;
        width: 100%;
      }

      .current-case-info-column {
        .current-case-info-item {
          .value {
            width: calc(100% - 160px);
          }
        }
      }
    }

    .description-wrapper {
      .value {
        max-height: 100px;
        overflow: hidden;
        transition: max-height 400ms;
      }

      .ant-btn {
        margin: 10px 0 0 -15px;
        color: $primary-button-green;
        font-weight: 500;
        width: 190px;
        display: flex;

        img {
          transition: all 200ms;
          margin: 8px 0 0 auto;
        }
      }

      &.full {
        .value {
          max-height: 400px;
          overflow-y: scroll;
        }

        .ant-btn {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }

    .task-link-wrapper {
      margin-top: 35px;

      .task-link-title {
        font-size: 18px;
        font-weight: 500;
        color: $nav-link-hover-green;
        margin: 0 0 15px 0;
      }

      .task-link-item {
        display: flex;
        align-items: center;
        padding: 5px 7px;
        background-color: $expanded-row-background-green;
        border: 1px solid $border-blue;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color ease-in-out 300ms;

        &:hover {
          background-color: $border-blue;
          border-color: $nav-link-active-green;
        }

        &:active {
          box-shadow: 0 0 2px 0 $gray;
        }

        .link-icon-wrapper {
          position: relative;
          height: 30px;

          img {
            &:first-child {
              width: 26px;
              height: 30px;
              margin-right: 10px;
            }

            &:last-child {
              position: absolute;
              left: 6px;
              top: 10px;
            }
          }
        }

        .link {
          color: $primary-button-green;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          -webkit-user-select: none; /* Safari */
          -ms-user-select: none; /* IE 10 and IE 11 */
          user-select: none;
        }
      }
    }
  }
}

.close-case-modal {
  .close-case-content {

    h4 {
      font-size: 20px;
      margin: 0 0 30px 0;
      font-weight: 600;
      color: $primary-button-green;
      text-align: center;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;

      .ant-btn {
        width: 90px;
        height: 30px;
        padding-top: 3px;
        padding-bottom: 4px;
      }
    }
  }

  .close-case-label {
    margin-bottom: 6px;
    color: $primary-button-green;
    font-size: 14px;
    font-weight: 500;
  }

  .close-case-textarea {
    width: 100%;
    margin-bottom: 30px;
  }
}

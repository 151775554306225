@import "styles/colors.scss";

.customer-section {
  background: $background-gray;
  border: 1px solid $border-gray;
  border-radius: 5px;
  width: 100%;
  padding: 5px;

  &-title {
    color: $primary-button-green;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  &-info {
    display: flex;
    gap: 10px;
  }
}

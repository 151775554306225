@import "styles/colors.scss";

.contract-us-modal {
  .contract-us-content {
    padding: 10px 40px;
    color: $primary-background-green;

    &-accent {
      color: $primary-button-green;
    }

    h4 {
      font-size: 24px;
      margin: 0 0 12px 0;
      font-weight: 600;
      color: $primary-button-green;
    }

    .buttons-wrapper {
      display: flex;
      justify-content: space-between;

      .ant-btn {
        width: 90px;
        height: 30px;
        padding-top: 3px;
        padding-bottom: 4px;
      }
    }
  }

  .contract-us-text {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

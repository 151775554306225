@import "styles/colors.scss";

.cookies {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: calc(100% - 248px);
  z-index: 1000;
  background: $background-gray;
  border: 1px solid $light-gray;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  gap: 15px;
  align-items: center;

  &.mobile {
    width: calc(100% - 20px);
    left: 10px;
    bottom: 60px;
    flex-direction: column;
    align-items: flex-start;
  }

  &-content {
    &-title {
      color: $primary-button-green;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }

  &-buttons {
    display: flex;
    gap: 10px;
    margin-left: auto;

    &.mobile {
      margin: auto;
    }
  }
}

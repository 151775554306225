.create-contract-button {
  display: flex;
  margin-left: auto;
  font-weight: 500;
}

.create-contract-modal {
  width: 410px !important;

  .ant-modal-content {
    padding: 30px 40px;
  }
}

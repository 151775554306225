@import "styles/colors.scss";

.registration-page-wrapper {
  .registration-form-wrapper {
    width: 600px;
    background-color: $white;
    padding: 30px 50px 40px 50px;
    border-radius: 20px;

    .login-error {
      padding: 4px 8px;
      margin-bottom: 24px;
      border: 1px solid rgba(255, 0, 0, 0.2);
      border-radius: 4px;
      background-color: rgba(255, 0, 0, 0.1);
      color: red;
    }

    .submit-notification {
      color: $primary-background-green;
    }

    h3 {
      font-weight: 600;
      font-size: 18px;
      color: $primary-background-green;
      margin: 0 0 20px 0;
    }

    .mg-btm-0 {
      margin-bottom: 0;
    }
  }

  .bottom-description {
    color: $white;
    font-size: 16px;
    text-align: center;

    a {
      color: $input-border-hover-green;
    }
  }

  &.mobile {
    width: 100%;

    .registration-form-wrapper {
      width: calc(100% - 20px);
      margin: 0 10px;
      padding: 30px;
    }

    .bottom-description {
      padding: 0 10px;
    }
  }
}

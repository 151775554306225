@import "styles/colors.scss";

.alias-input {
  height: 22px;
  background-color: $light-blue;
  border-radius: 3px;
  display: flex;

  input {
    background: none;
    color: $link-button-green;
  }
}

.alias-wrapper {
  display: flex;
  height: 22px;

  .alias-value {
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
      text-decoration: underline;
    }
  }

  .add-title {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $table-header-green;
    font-size: 13px;
  }

  .prolonged {
    background-color: $table-header-green;
    padding: 0 5px 3px 5px;
    border-radius: 3px;
    color: $white;
    font-size: 13px;
    font-weight: 500;
    margin-right: 20px;

    &.active {
      background-color: $input-border-hover-green;
    }

    &.warning {
      background-color: $warning-red-text;
    }
  }

  .edit-button {
    height: 12px;
    width: 12px;
  }

  .alias-value {

    &.alias-active {
      color: $link-button-green;
    }

    &.alias-warning {
      color: $warning-red-text-secondary;
    }
  }
}

.list-equipment-header-wrapper {
  .filter-wrapper {
    margin-bottom: 10px;
  }
}

.list-equipment-mobile {
  &-filter {
    display: flex;
    align-items: center;
    gap: 7px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 12px;

    &-control {
      padding: 11px;
    }
  }

  &-search {

  }
}

.list-equipment-tabs {
  padding: 0 20px;
}

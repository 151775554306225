@import "styles/colors.scss";

.invite-form-new {
  .add-email-button {
    margin-left: -12px;
    font-size: 14px;
    font-weight: 400 !important;
    margin-top: 5px;
  }

  .mg-btn-0 {
    margin-bottom: 0;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    color: $primary-background-green;
  }
}

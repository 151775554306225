@import "styles/colors.scss";

.contracts-table {
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        &.contract-number-table-cell {
          p {
            &:hover {
              text-decoration: underline;
            }
          }
        }

        &.description-table-cell {
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100vw - 1100px);
          }
        }

        .warning {
          font-weight: 600;
          color: $warning-yellow;
        }
      }

      &:hover {
        cursor: pointer;

        & > td {
          background-color: $light-blue !important;
        }
      }
    }
  }
}

@import "styles/colors.scss";

.cases-table {
  .ant-table {
    border: 1px solid $border-gray;
    border-radius: 10px;

    .ant-table-thead {
      .ant-table-cell {
        border-bottom: 1px solid $border-gray;
        border-top: none !important;
        color: $table-header-green;
        cursor: pointer;

        &:first-child {
          border-start-start-radius: 10px !important;
        }

        &:last-child {
          border-start-end-radius: 10px !important;
        }

        &:before {
          display: none;
        }

        &.ant-table-column-sort {
          background-color: $background-gray;

          .ant-table-column-sorter {
            .ant-table-column-sorter-inner {
              .ant-table-column-sorter-up, .ant-table-column-sorter-down {
                &.active {
                  color: $primary-button-green !important;
                }
              }
            }
          }
        }
      }
    }

    .ant-table-thead, .ant-table-tbody {
      .ant-table-cell {
        font-weight: 400;
        font-size: 14px;
        padding: 5px 15px 4px 15px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          border-top: 1px solid $border-gray !important;
          border-bottom: none !important;
          color: $note-message-green;

          &.customer-table-cell {
            p {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 170px;
            }
          }

          &.ant-table-column-sort {
            background: none;
          }

          p {
            margin: 0;

            &.active {
              font-weight: 600;
              color: $primary-background-green;

              &.thin {
                font-weight: 400;
              }
            }

            &.align-center {
              padding-left: 35px;
            }

            &.severity {
              font-weight: 500;
              color: $note-message-green;

              &.active {
                &.critical {
                  color: $primary-button-green;
                }

                &.major {
                  color: $link-button-green;
                }

                &.normal {
                  color: $input-border-hover-green;
                }

                &.info {
                  color: $nav-link-active-green;
                }
              }
            }

            .dot-prefix {
              padding-right: 20px;
              padding-bottom: 2px;

              &.warning {
                margin: 0 0 -2px -2px;
                padding-right: 17px;
              }
            }
          }
        }

        &:first-child {
          .ant-table-cell {
            border-top: none !important;
          }
        }

        &:last-child {
          .ant-table-cell {
            &:first-child {
              border-end-start-radius: 10px !important;
            }

            &:last-child {
              border-end-end-radius: 10px !important;
            }
          }
        }

        &:hover {
          .ant-table-cell, .ant-table-column-sort {
            background-color: $light-background-gray;

            &:first-child {
              border-start-start-radius: 0 !important;
              border-end-start-radius: 0 !important;
            }

            &:last-child {
              border-start-end-radius: 0 !important;
              border-end-end-radius: 0 !important;
            }
          }
        }

        .subject-cell {
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 22px;
            max-width: calc(100vw - 1000px);
          }
          a {
            color: $table-header-green;

            &.active {
              color: $primary-button-green;
              font-weight: 600;
            }
          }

          
        }

        &.opened, &.expanded {
          background-color: $expanded-row-background-green;

          .subject-cell {
            p {
              height: initial;
              white-space: initial;
              text-overflow: initial;
            }
          }
        }
      }

      .ant-table-expanded-row, .expanded {
        background-color: $expanded-row-background-green;

        .ant-table-cell {
          border-top: none;
          background: none;

          .expanded-menu {
            .ant-btn {
              &.create-case-button {
                margin-top: 0;
              }

              img {
                margin-right: 5px;
              }

              &:first-child {
                margin-right: 10px;
              }
            }

            .close-reason-wrapper {
              min-width: 500px;

              .cancel-button {
                border-color: $border-gray;

                &:hover {
                  border-color: $input-border-hover-green !important;
                  color: $input-border-hover-green !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-tag {
    background-color: $light-blue;
    border-color: $light-timestamp-green;
    border-radius: 5px;
    color: $note-message-green;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    height: 19px;
    line-height: 13px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;

    &.pending {
      background-color: $note-message-green;
      color: $white;
      border-color: $dark-border-green;
    }

    &.active {
      &.warranty {
        background-color: $light-blue;
        color: $link-button-green;
        border-color: $nav-link-active-green;
      }

      &.fix_time {
        background-color: $input-border-hover-green;
        color: $white;
        border-color: $link-button-green;
      }

      &.full_time {
        background-color: $active-tag-background-green;
        color: $primary-button-green;
        border-color: $input-border-hover-green;
      }

      &.next_business_day {
        background-color: $white;
        color: $link-button-green;
        border-color: $input-border-hover-green;
      }
    }
  }
}

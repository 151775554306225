@import "styles/colors.scss";

.contract-tabs {
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.contract-tab {
  width: fit-content;
  display: flex;
  align-items: center;

  &-step {
    display: flex;
    align-items: center;
    border: 1px solid $border-gray;
    border-radius: 100px;
    gap: 15px;
    padding: 0 15px;
    color: $border-gray;

    &-complete {
      border: 1px solid $link-button-green;
      color: $primary-background-green;
    }

    &-active {
      background: $link-button-green;
      color: white;
    }

    &-value {
      border: 1px solid $border-gray;
      width: 22px;
      height: 22px;
      border-radius: 12px;
      position: relative;

      &-active {
        border: 1px solid $link-button-green;
      }

      &-ins {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-text {
      padding: 13px 0;
    }
  }

  &-divider {
    width: 100px;
    border-bottom: 1px solid $border-gray;
    margin-bottom: -1px;
  }
}

.contract-tab-area {
  padding: 20px 30px;
  background: $background-gray;
  border-radius: 10px;
  margin: 0 auto 30px;
  max-width: 1046px;

  &-title {
    margin-bottom: 5px;
  }

  &-subtitle {
    font-size: 20px;
    font-weight: bold;
    color: $primary-background-green;
  }

  &-group {
    display: flex;
    justify-content: space-between;

    &-back-button {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }

  &-button {
    display: flex;
    margin: 0 auto;
    width: 100px;
  }
}

.contract-tab-first {
  width: 270px;
  height: 250px;
  padding: 20px;
  border: 1px solid $primary-button-green;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto 43px;

  &-img {
    text-align: center;
    margin: 0 auto;
  }

  &-title {
    color: $primary-button-green;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  ul {
    margin-left: -20px;
  }

  &-circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: $primary-button-green;
    margin-top: auto;
    position: relative;

    &-active {
      background-color: white;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      position: absolute;
      top: 7px;
      left: 7px;
      display: flex;
      align-items: center;
    }
  }
}

.contract-tab-second {
  &-label {
    margin-top: 30px;
    margin-bottom: 15px;
  }

  .radio {
    display: flex;
    gap: 30px;

    &-option {
      background: white;
      border: 1px solid $border-gray;
      border-radius: 5px;
      display: flex;
      gap: 15px;
      height: 42px;
      align-items: center;
      color: $primary-background-green;
      width: 235px;
      padding: 0 15px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      &:hover {
        border-color: $note-message-green;
      }

      &-circle {
        border-radius: 50%;
        width: 15px;
        height: 15px;
        border: 1px solid $border-gray;
        position: relative;

        &-active {
          background: $primary-button-green;
        }

        &-point {
          background-color: white;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          position: absolute;
          top: 4px;
          left: 4px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.contract-tab-third {
  display: flex;
  margin-bottom: 34px;

  &-divider {
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    &-line {
      height: 76px;
      border-right: 1px solid $border-gray;
    }
  }

  &-big-button {
    height: 200px;
    border-radius: 10px;
    margin-top: 30px;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    text-align: center;
    border: 1px solid $border-gray;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;

    &:hover {
      border-color: $note-message-green;
    }

    &-active {
      border-color: $primary-button-green;
      background: $light-blue;
    }

    //&-disabled {
    //  cursor: default;
    //  background: white;
    //  text-align: center;
    //  border: 1px solid $border-gray;
    //}

    &-accent {
      color: $primary-button-green;
      font-weight: bold;
    }
  }
}

.contract-tab-fourth {

}

.contract-create-status {
  margin: 35px 90px 68px;
  min-width: 500px;
  text-align: center;

  &-title {
    font-size: 24px;
    font-weight: 500;
    color: $primary-button-green;
    margin-bottom: 15px;
  }

  &-text {
    color: $note-message-green;
    margin-bottom: 15px;
    font-size: 18px;
  }

  &-description {
    color: $note-message-green;
    margin-bottom: 30px;
    font-size: 18px;
  }

  &-accent {
    color: $primary-button-green;
    font-weight: 500;
  }

  .forward-button {
    width: 142px;

    & > img {
      margin-left: 10px;
      margin-bottom: -2px;
    }
  }
}
@import "styles/colors.scss";

.contractor-form-wrapper {
  .back-button {
    margin-bottom: 25px;

    img {
      margin-right: 10px;
      margin-bottom: -1px;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      color: $primary-background-green;
    }
  }

  .edit-button {
    width: 100px;

    img {
      margin-left: 10px;
      margin-bottom: -1px;
    }
  }

  .contractor-view-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .contractor-info {
    width: 450px;
  }

  .contractor-info-item {
    margin-left: 25px;
    margin-bottom: 15px;
  }

  .contractor-info-title {
    color: $table-header-green;
    margin-bottom: 8px;
  }

  .contractor-info-value {

  }

  .contractor-form {
    max-width: 400px;
    padding: 0 25px;
  }

  .buttons-wrapper {
    display: flex;
    margin-top: 35px;

    .ant-btn {
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
  }
}

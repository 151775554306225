.downloads-header-wrapper {
  display: flex;
  margin-bottom: 20px;

  .add-file-button {
    margin-right: 20px;
  }

  .search-input {
    max-width: 300px;
    margin-left: auto;
    margin-right: 20px;
  }
}

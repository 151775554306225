@import "styles/colors.scss";

.download-card-wrapper {
  width: calc(25% - 11.25px);
  padding: 20px;
  border-radius: 7px;
  border: 1px solid $border-blue;
  background-color: $background-gray;
  margin-right: 15px;
  margin-bottom: 15px;
  transition-property: background-color;
  transition-duration: 300ms;
  cursor: pointer;

  &:nth-child(4n + 4) {
    margin-right: 0;
  }

  &:hover {
    background-color: $light-blue;
  }

  .download-card-header {
    display: flex;
    margin-bottom: 10px;

    p {
      margin: 0;
    }

    .download-card-date {
      font-weight: 400;
      color: $note-message-green;
    }

    .download-card-deadline {
      margin-left: auto;
      background-color: $nav-link-active-green;
      color: $white;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 500;
      padding: 1px 8px 0 8px;
    }

    .download-card-title {
      font-weight: 600;
      font-size: 16px;
      color: $note-message-green;
    }
  }

  .download-card-icon {
    padding-top: 10px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid $border-blue;

    .download-card-file-name {
      margin: 20px 0 15px 0;
      font-weight: 400;
      color: $note-message-green;
    }
  }

  .download-card-footer {
    display: flex;
    margin-top: 15px;

    .download-card-footer-info {
      p {
        margin: 0;
      }

      .download-card-footer-title {
        font-weight: 600;
        color: $link-button-green;
      }

      .download-card-footer-value {
        font-weight: 400;
        color: $note-message-green;
      }
    }

    .download-button {
      padding: 4px 10px;
      background-color: $link-button-green;
      margin-left: auto;
      border-color: $link-button-green;

      &:hover {
        background-color: $primary-button-green;
        border-color: $primary-button-green !important;
      }
    }
  }
}

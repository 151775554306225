@import "styles/colors.scss";

.slim-table {
  .ant-table {
    .ant-table-thead, .ant-table-tbody {
      .ant-table-cell {
        font-size: 12px;
        padding: 5px 15px 4px 15px;

        &.status-table-cell {
          padding: 0 0 0 15px;

          & > .status {
            display: flex;
          }
        }

        &.description-table-cell {
          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100vw - 950px);
          }

          &.serial-number {
            p {
              max-width: 160px;
            }
          }
        }

        .ant-tag {
          font-size: 12px;
          height: 19px;
          line-height: 13px;
          width: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .equipment-status {
          display: flex;
        }

        .equipment-number {
          font-weight: 600;
          color: $primary-background-green;
        }
      }
    }
  }
}

.contracts-header-wrapper {
  position: relative;

  .contracts-search-input {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.search-input {
  position: absolute;
  top: 0;
  width: 300px;
  right: 0;
}
@import "styles/colors.scss";

.table-card-switch-wrapper {
  width: 72px;
  height: 32px;
  padding: 2px;
  border-radius: 4px;
  background-color: $background-gray;
  display: flex;
  position: relative;
  cursor: pointer;

  .table-card-switcher {
    width: 33px;
    height: 28px;
    background-color: $light-timestamp-green;
    border-radius: 2px;
    left: 2px;
    position: absolute;
    transition-property: left;
    transition-duration: 200ms;

    &.off {
      left: 37px;
    }
  }

  .table-card-image {
    z-index: 2;
    width: 33px;
    height: 28px;
    padding: 7px 10px;

    &:last-child {
      margin-left: 2px;
    }
  }
}
